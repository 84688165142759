<template>
  <div>
    <!-- <Navbar />
    <Sidebar /> -->
    <div
      style="display:flex;gap:10px;"
      class="main1"
    >
      <b-form-group>
        <label style="padding-bottom:5px;font-weight:600;">{{ $t('FilterByPickUpDate') }}</label>
        <div
          style="display:flex;gap:10px"
        >
          <b-form-group
            :label="$t('StartDate')"
          >
            <date-picker
              v-model="startDate"
              format="YYYY-MM-DD"
              type="date"
              value-type="format"
              :placeholder="$t('SelectD')"
            />
          </b-form-group>
          <b-form-group
            :label="$t('EndDate')"
          >
            <date-picker
              v-model="endDate"
              format="YYYY-MM-DD"
              type="date"
              value-type="format"
              :placeholder="$t('SelectD')"
            />
          </b-form-group>
        </div>

      </b-form-group>

    </div>
    <section
      class="main"
    >
      <div style="width: 100%;">

        <!-- <h3
          v-if="getLoggedInUser.role === 'TeamLeader'"
          style="font-size: 35px; color:#262E6C ; font-weight: bold;"
        >{{ $t('TeamName') }}: {{ getTeamsNames[0].teamName }}</h3>
        <h3
          v-else
          style="font-size: 35px; color:#262E6C ; font-weight: bold;"
        >{{ $t('ProductionManagement') }}</h3> -->
        <ul style="padding: 0; ">
          <li
            v-for="item in getTeamsNames"
            :key="item.teamNameId"
            class="pm-li"
          >
            <div
              style="display: flex; justify-content: space-between;"
              @click.stop="getTeam(item.teamNameId)"
            >
              <div
                class="activeOrders"
                style="display: flex; align-items: center;"
              >
                <h5
                  style="color:#393939; font-weight: 600; font-size: 20px;"
                >{{ $t('TeamName') }}: {{ item.teamName }}</h5>
                <b> {{ $t('ActiveOrders') }}: {{ item.countItems == null ? 0 : item.countItems }}</b>
              </div>
              <button
                class="btn btn-outline-primary"
                :class="{
                  'hovered': showHide && activeId == item.teamNameId,
                  'notHovered': !(showHide && activeId == item.teamNameId)
                }"
                type="button"
                @click.stop="getTeam(item.teamNameId)"
              >
                {{ showHide == true && activeId == item.teamNameId ? $t('Hide') : $t('Show') }}
                <span v-if="showHide == true && activeId == item.teamNameId "><img
                  style="width:15px"
                  src="../assets/images/SyMshel.svg"
                ></span>
                <span v-if="!(showHide == true && activeId == item.teamNameId) "><img
                  style="width:15px"
                  src="../assets/images/SyQel.svg"
                ></span>
              </button>
            </div>
            <ul
              class="ul-order"
              style="z-index: -1"
            >
              <div
                v-if="getTeamOrders.length != 0"
                v-show="showHide == true && item.teamNameId == activeId"
                class="order-header"
              >
                <p style="width: 15%; margin-bottom: 0px;">
                  {{ $t('OrderNumber') }}
                </p>
                <p style="width: 20%; margin-bottom: 0px;">
                  {{ $t('FirstName') }}
                </p>
                <p style="width: 20%; margin-bottom: 0px;">
                  {{ $t('Email') }}
                </p>
                <p style="width: 10%; margin-bottom: 0px;">
                  {{ $t('Gender') }}
                </p>
                <p style="width: 20%; margin-bottom: 0px;">
                  {{ $t('PhoneNumber') }}
                </p>
                <p style="width: 20%; margin-bottom: 0px;">
                  {{ $t('OrderState') }}
                </p>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  style="visibility: hidden; margin-bottom: 0px; height: 10px;"
                >
                  {{ showO == true ? $t('Hide') : $t('Show') }} {{ $t('Items') }}
                </button>
              </div>
              <li
                v-for="itemd in getTeamOrders"
                v-show="showHide == true && item.teamNameId == activeId"
                :key="itemd.id"
              >
                <div class="order-list">
                  <div
                    class="order-card"
                    style="display: flex; align-items: center; cursor: pointer;"
                    @click.stop="orderItem(itemd.orderNumber, item.teamNameId, itemd.orderState); editEmployee = false"
                  >
                    <p style="width: 15%">
                      {{ itemd.orderNumber }}
                    </p>
                    <p style="width: 20%">
                      {{ itemd.ordererDto.firstName }} {{ itemd.ordererDto.lastname }}
                    </p>
                    <p style="width: 20%">
                      {{ itemd.ordererDto.email }}
                    </p>
                    <p style="width: 10%">
                      {{ $t(itemd.ordererDto.gender) }}
                    </p>
                    <p style="width: 20%">
                      +{{ countryCallingCode[itemd.ordererDto.countryCode] }} {{ itemd.ordererDto.telephoneNumber }}
                      <!-- {{ itemd.ordererDto.telephoneNumber }} -->
                    </p>
                    <p
                      style="width: 20%; font-weight: bold;"
                      :style="itemd.orderState === 'QualityCheckNotPassed' ? 'color: #e51515' : itemd.orderState === 'InHandSewingQualityNotPassed' ? 'color: #e51515' : itemd.orderState === 'InSewingQualityNotPassed' ? 'color: #e51515' : itemd.orderState === 'InControlCheck' ? 'color: #128EEE' : itemd.orderState === 'InHandSewingQualityPassed' ? 'color: #15E582' : itemd.orderState === 'InSewingQualityPassed' ? 'color: #15E582' : itemd.orderState === 'InPatternCompleted' ? 'color: #15E582' : itemd.orderState === 'InPatternQualityNotPassed' ? 'color: #e51515' :itemd.orderState === 'InPatternQualityPassed' ? 'color: #15E582' : itemd.orderState === 'InPattern' ? 'color: #15E582' : itemd.orderState === 'QualityPassed' ? 'color: #15E582' : itemd.orderState === 'ReadyToDeliver' ? 'color: #15E582' : itemd.orderState === 'Delivered' ? 'color: #15E582' : itemd.orderState === 'InHandSewing' ? 'color: #15E582' : itemd.orderState === 'InSewing' ? 'color: #15E582' : itemd.orderState === 'InProduction' ? 'color: #15E582' : itemd.orderState === 'Confirmed' ? 'color: #128EEE' : itemd.orderState === 'Assigned' ? 'color: #128EEE' : 'color: #e51515'"
                    >
                      {{ $t(itemd.orderState) }}
                    </p>
                    <button
                      class="btn btn-outline-primary"
                      style="width: 10%; margin-bottom: 15px;"
                      :class="{
                        'hovered': showO == true && activeOrderStatus == itemd.orderNumber,
                        'notHovered': !(showO == true && activeOrderStatus == itemd.orderNumber)
                      }"
                      type="button"
                      @click.stop="orderItem(itemd.orderNumber, item.teamNameId, itemd.orderState); editEmployee = false"
                    >
                      {{ showO == true && activeOrderStatus == itemd.orderNumber ? $t('Hide') : $t('Show') }}
                      <span v-if="showO == true && activeOrderStatus == itemd.orderNumber"><img
                        style="width:15px"
                        src="../assets/images/SyMshel.svg"
                      ></span>
                      <span v-if="!(showO == true && activeOrderStatus == itemd.orderNumber)"><img
                        style="width:15px"
                        src="../assets/images/SyQel.svg"
                      ></span>
                    </button>
                  </div>
                  <!-- Repeat the order-card div for each item in the list -->
                </div>
                <ul v-if="showO && activeOrderStatus == itemd.orderNumber">
                  <li
                    v-for="itemz in getArticles"
                    v-show="itemd.orderNumber === activeOrderStatus"
                    :key="itemz.id"
                    style="border-top: 1px solid lightgray; padding-top: 2%;"
                  >
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span style="margin-right: 40px">
                        {{ $t('ArticleName') }}: {{ itemz.articleNumber }}</span>
                      <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
                        <button
                          v-if="orderStateStatus == 'ReadyToDeliver'"
                          v-b-modal.send-to-stock
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="showLocations(itemd.orderNumber,itemz.orderItemId, itemd.ordererDto.clientId)"
                        >
                          {{ $t('SendToStock') }}
                        </button>
                        <button
                          v-if="orderStateStatus != 'OnHold'"
                          v-b-modal.on-hold-item
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="showLocations(itemd.orderNumber,itemz.orderItemId, itemd.ordererDto.clientId)"
                        >
                          {{ $t('SentOnHold') }}
                        </button>
                        <button
                          v-if="orderStateStatus == 'OnHold'"
                          v-b-modal.return-back-hold
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="showTeams(itemd.orderNumber,itemz.orderItemId)"
                        >
                          {{ $t('ReturnBackFromoHold') }}
                        </button>
                        <button
                          v-if="orderStateStatus != 'Fitting'"
                          v-b-modal.on-fitting-item
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="showLocations(itemd.orderNumber,itemz.orderItemId, itemd.ordererDto.clientId)"
                        >
                          {{ $t('SentOnFitting') }}
                        </button>
                        <button
                          v-if="orderStateStatus == 'Fitting'"
                          v-b-modal.return-back-fitting
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="showTeams(itemd.orderNumber,itemz.orderItemId)"
                        >
                          {{ $t('ReturnBackFromFitting') }}
                        </button>
                        <button
                          v-b-modal.return-back-state
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="showTeams(itemd.orderNumber,itemz.orderItemId)"
                        >
                          {{ $t('ReturnBack') }}
                        </button>
                        <button
                          v-b-modal.view-orderItem-details
                          class="btn btn-outline-primary"
                          style="height: 40px;margin-left: 10px;"
                          type="button"
                          @click="loadAllDetails(itemz.orderItemId),
                                  loadDescription(itemd.orderNumber),
                                  loadS(itemz.orderItemId),
                                  showMaterials(itemd.orderNumber, itemz.orderItemId),
                                  loadOrders(itemz.orderItemId),
                                  loadOrderImages(itemd.orderNumber)
                          "
                        >
                          {{ $t('Show') }} {{ $t('Details') }}
                        </button>
                        <button
                          v-b-modal.processing-users
                          class="btn btn-outline-primary"
                          style="height: 40px; margin-left: 10px;"
                          type="button"
                          @click="callEmployees(itemd.orderState, itemz.orderItemId, itemz.state)"
                        >
                          {{ $t('Show') }} {{ $t('Employees') }}
                        </button>
                        <button
                          v-if="itemz.state == 3"
                          v-b-modal.edit-orderItem-materialVariants
                          style="margin-left: 5px;"
                          class="btn btn-outline-primary"
                          type="button"
                          @click="loadAllDetails(itemz.orderItemId), loadDescription(itemd.orderNumber), loadS(itemz.orderItemId), showMaterials(itemd.orderNumber, itemz.orderItemId),loadOrders(itemz.orderItemId)"
                        >
                          {{ $t('Edit') }} {{ $t('MaterialVariants') }}
                        </button>
                        <img
                          v-b-tooltip="$t('ClickToViewPhoto')"
                          style="height: 43px; border-radius: 6px; cursor: pointer;"
                          src="../assets/images/imageicon.svg"
                          alt=""
                          @click.stop="getAllImages(itemz.articleNumber)"
                        >
                        <!-- <button
                          v-if="itemz.processingUser == null"
                          v-b-modal.modal-assign-employee
                          style="margin-left: 5px;"
                          class="btn btn-outline-primary"
                          type="button"
                          @click="loadEmployees(itemz.orderItemId)"
                        >
                          {{ $t('AssignEmployee') }}
                        </button> -->
                        <div
                          v-if="itemz.processingUser != null"
                        >
                          <span style="margin-left: 20px;">
                            {{ $t('CurrentEmployee') }}:</span>
                          <div style="display: flex; align-items: center;">
                            <b-input
                              v-if="editEmployee == false"
                              v-model="itemz.processingUser"
                              type="text"
                              disabled
                              style="margin-left: 10px;"
                            />
                            <vue-select
                              v-if="editEmployee == true"
                              v-model="employeeId"
                              :options="getTeamOverview.users"
                              :placeholder="$t('SelectEmployee')"
                              :reduce="(e) => e.userId"
                              label="name"
                              aria-describedby="input-1-live-feedback"
                              style="margin-left: 10px; min-width: 220px;"
                            />
                            <div
                              class="inside-actions"
                              @click="editEmployee = !editEmployee"
                            >
                              <b-icon-pencil v-if="editEmployee == false" />
                              <b-icon-check2
                                v-if="editEmployee == true"
                                @click="editProcessingUser(itemz.orderItemId)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div>
                      <div style="margin-right: 10px; margin-top: 20px">
                        <!-- <p>{{ $t('ItemState') }}:</p> -->
                        <div
                          v-if="stateType === 'Low'"
                          style="display: flex; flex-wrap: wrap"
                        >
                          <div
                            v-for="(stage, idx) in stagesXharra"
                            :key="idx"
                            :class="{
                              failed: itemz.stateDtos[9].stateProcessed == true,
                              active: itemz.stateDtos[idx].stateProcessed == true && itemz.stateDtos[9].stateProcessed == false,
                              activee: itemz.stateDtos[idx].stateProcessed == true && idx != itemz.state ,
                            }"
                            class="stage-card"
                            @click="changeSXharra(stage.value, itemd.orderNumber, itemz.stateDtos[itemz.state].stateName, itemz.orderItemId, itemz.articleNumber)"
                          >
                            {{ stage.name == $t('QualityCheckNotPassed') ? `${stage.name} ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : stage.name }}
                          </div>
                        </div>
                        <div
                          v-if="stateType === 'Medium'"
                          style="display: flex; flex-wrap: wrap"
                        >
                          <div
                            v-for="(stage, idx) in stagesNila"
                            :key="idx"
                            :class="{
                              failed: itemz.stateDtos[11].stateProcessed == true,
                              active: itemz.stateDtos[idx].stateProcessed == true && itemz.stateDtos[11].stateProcessed == false,
                              activee: itemz.stateDtos[idx].stateProcessed == true && idx != itemz.state ,
                            }"
                            class="stage-card"
                            @click="changeSNila(stage.value, itemd.orderNumber, itemz.stateDtos[itemz.state].stateName, itemz.orderItemId, itemz.articleNumber)"
                          >
                            {{ stage.name == $t('QualityCheckNotPassed') ? `${stage.name} ${itemz.qualityCheckerCounter >0 ? `: ${itemz.qualityCheckerCounter}`:''} ` : stage.name }}
                          </div>
                        </div>
                        <div
                          v-if="stateType === 'High' "
                          style="display: flex; flex-wrap: wrap"
                        >
                          <div
                            v-for="(stage, idx) in stages"
                            :key="idx"
                            v-b-tooltip.hover.bottom
                            :title="stage.name"
                            :class="{
                              failed: itemz.stateDtos[31].stateProcessed == true || itemz.qualityCheckerCounter > 2,
                              active: itemz.stateDtos[idx].stateProcessed == true && itemz.stateDtos[30].stateProcessed == false,
                              activee: itemz.stateDtos[idx].stateProcessed == true && idx != itemz.state && itemz.stateDtos[21].stateProcessed == false,
                              disabled: itemz.stateDtos[itemz.state].stateName === 'InPatternQualityPassed' && idx === (itemz.state + 1) ||
                                itemz.stateDtos[itemz.state].stateName === 'InSewingQualityPassed' && idx === (itemz.state + 1) ||
                                itemz.stateDtos[itemz.state].stateName === 'InHandSewingQualityPassed' && idx === (itemz.state + 1),
                            }"
                            class="stage-card"
                            @click="changeS(stage.value, itemd.orderNumber, itemz.stateDtos[itemz.state].stateName, itemz.orderItemId, itemz.articleNumber, itemz.qualityCheckerCounter,stage.name)"
                          >
                            <img
                              v-if="client != 'NotaBrillant'"
                              class="iconat"
                              alt=""
                              :src="require(`../assets/images/Logo-${getLoggedInUser.role == 'TeamLeader'? idx+=1 : getLoggedInUser.role == 'Deliverer'? idx+=7 : idx+1}.svg`)"
                            >
                            <img
                              v-else
                              class="iconat"
                              alt=""
                              :src="require(`../assets/images/NotaBrilliant/Logo-${getLoggedInUser.role == 'TeamLeader'? idx+=1 : getLoggedInUser.role == 'Deliverer'? idx+=7 : idx+1}.svg`)"
                            >
                            <div class="first-div">
                              <div v-if="nState == 'InPatternQualityNotPassed' ">
                                {{ stage.name == 'InPatternQualityNotPassed' ? `Failed Attempts : ${itemz.qualityCheckerCounter}` : '' }}
                              </div>
                              <div v-else-if="nState == 'InSewingQualityNotPassed' ">
                                {{ stage.name == 'InSewingQualityNotPassed' ? `Failed Attempts : ${itemz.qualityCheckerCounter}` : '' }}
                              </div>
                              <div v-else-if="nState == 'InHandSewingQualityNotPassed' ">
                                {{ stage.name == 'InHandSewingQualityNotPassed' ? `Failed Attempts : ${itemz.qualityCheckerCounter}` : '' }}
                              </div>
                              <div v-else-if="nState == 'QualityCheckNotPassed'">
                                {{ stage.value == 'QualityCheckNotPassed' ? `Failed Attempts : ${itemz.qualityCheckerCounter}` : '' }}
                              </div>
                            </div>
                            <div class="second-div">
                              <div v-if="nState == 'InPatternQualityNotPassed' ">
                                <p>{{ stage.value == 'InPatternQualityNotPassed' ? `${shortWords(stage.name)}: ${itemz.qualityCheckerCounter}` : shortWords(stage.name) }}</p>
                              </div>
                              <div v-else-if="nState == 'InSewingQualityNotPassed' ">
                                <p> {{ stage.value == 'InSewingQualityNotPassed' ? `${shortWords(stage.name)}: ${itemz.qualityCheckerCounter}` : shortWords(stage.name) }}</p>
                              </div>
                              <div v-else-if="nState == 'InHandSewingQualityNotPassed' ">
                                <p>{{ stage.value == 'InHandSewingQualityNotPassed' ? `${shortWords(stage.name)}: ${itemz.qualityCheckerCounter}` : shortWords(stage.name) }}</p>
                              </div>
                              <div v-else-if="nState == 'QualityCheckNotPassed'">
                                <p>{{ stage.value == 'QualityCheckNotPassed' ? `${shortWords(stage.name)}: ${itemz.qualityCheckerCounter}` : shortWords(stage.name) }}</p>
                              </div>
                              <div v-else>
                                <p>{{ stage.value == true ? `${shortWords(stage.name)}: ${itemz.qualityCheckerCounter}` : shortWords(stage.name) }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <div style="padding: 0px 30px 10px 118px">
      <b-pagination
        v-if="getTotalItemsForTeams > 15"
        v-model="page"
        style="border:none !important;padding:none !important"
        :total-rows="getTotalItemsForTeams"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        prev-class="prev-itemi"
        next-class="next-itemi"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <ChangeStatusModal
      :id="activeId"
      :qualitycheck="qualitycheck"
      :c-status="comingStatus"
      :t-status="teamNameStatus"
      :l-status="locationStatus"
      :control-check-status="controlCheckStatus"
      :article-number="articleNumber"
      :q-failed="changeModal"
      :show-team="showTeamInModal"
      :show-location="showLocation"
      :completed-steps="completedSteps"
      :show-control-check="showTeamControlCheck"
      :order-failed="qualityFailed"
      :n-state="nState"
      @changeOrderStatus="changeStage"
      @qualityChFailed="changeStage"
      @teamName="changeStage"
      @locationName="changeStage"
      @controlCheck="changeStage"
    />
    <ChangeStatusModalNila
      :id="activeId"
      :qualitycheck="qualitycheck"
      :c-status="comingStatus"
      :t-status="teamNameStatus"
      :l-status="locationStatus"
      :control-check-status="controlCheckStatus"
      :article-number="articleNumber"
      :q-failed="changeModal"
      :show-team="showTeamInModal"
      :show-location="showLocation"
      :completed-steps="completedSteps"
      :show-control-check="showTeamControlCheck"
      :order-failed="qualityFailed"
      :n-state="nState"
      @changeOrderStatus="changeStage"
      @qualityChFailed="changeStage"
      @teamName="changeStage"
      @locationName="changeStage"
      @controlCheck="changeStage"
    />
    <ChangeStatusModalNota
      :id="activeId"
      :qualitycheck="qualitycheck"
      :c-status="comingStatus"
      :t-status="teamNameStatus"
      :l-status="locationStatus"
      :control-check-status="controlCheckStatus"
      :article-number="articleNumber"
      :q-failed="changeModal"
      :show-team="showTeamInModal"
      :show-location="showLocation"
      :completed-steps="completedSteps"
      :show-control-check="showTeamControlCheck"
      :order-failed="qualityFailed"
      :n-state="nState"
      @changeOrderStatus="changeStage"
      @qualityChFailed="changeStage"
      @teamName="changeStage"
      @locationName="changeStage"
      @controlCheck="changeStage"
    />
    <ViewAllDetailsOrderItem :order-item-id="orderItemId" />
    <EditOrderitemMaterialVariantInProductManagementVue :order-item-id="orderItemId" />
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      @hide="handleHide"
    />
    <AssignEmployeeModal
      :item-id="itemId"
      @assignEmployee="assignProcessingEmployee"
    />
    <ProcessingUserEmployees
      :state-employee="stateEmployee"
      :current-state-of-article="currentStateOfArticle"
      :order-item-id-employee="orderItemIdEmployee"
      @addedEmployees="addEmployees"
    />
    <OnHoldItem
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      :client-name-status="clientNameStatus"
      @returnBackHold="returnHold"
    />
    <SendToStock
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      :client-name-status="clientNameStatus"
      @sendToStock="returnToStock"
    />
    <OnFittingItem
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      :client-name-status="clientNameStatus"
      @returnBackFitting="returnFitting"
    />
    <ReturnBackState
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      @returnBackState="returnToState"
    />
    <ReturnBackFitting
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      @returnBackFitting="returnToFitting"
    />
    <ReturnBackOnHold
      :order-item-id-state="orderItemIdState"
      :order-number-state="orderNumberState"
      @returnBackHold="returnToHold"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox'
import Sidebar from '@/components/sidebars/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import ChangeStatusModal from '@/components/products/modals/ChangeStatusModal.vue'
import ChangeStatusModalNila from '@/components/products/modals/ChangeStatusModalNila.vue'
import AssignEmployeeModal from '@/components/products/modals/AssignEmployeeModal.vue'
import ProcessingUserEmployees from '@/components/ProcessingUserEmployees.vue'
import ChangeStatusModalNota from '@/components/products/modals/ChangeStatusNota.vue'
import ReturnBackState from '@/modals/ReturnBackState.vue'
import ReturnBackFitting from '@/modals/ReturnBackFitting.vue'
import ReturnBackOnHold from '@/modals/ReturnBackOnHold.vue'
import OnHoldItem from '@/modals/OnHoldItem.vue'
import SendToStock from '@/modals/SendToStock.vue'
import OnFittingItem from '@/modals/OnFittingItem.vue'
import DatePicker from 'vue2-datepicker';
import ViewAllDetailsOrderItem from '../modals/ViewAllDetailsOrderItem.vue'
import { client } from '../domainConfig'
import { countryCallingCodes } from '../countryCodes'
import EditOrderitemMaterialVariantInProductManagementVue from '../modals/EditOrderitemMaterialVariantInProductManagement.vue'
import 'vue2-datepicker/index.css';

export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    DatePicker,
    VueEasyLightbox,
    AssignEmployeeModal,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    ChangeStatusModal,
    ChangeStatusModalNila,
    ChangeStatusModalNota,
    ViewAllDetailsOrderItem,
    EditOrderitemMaterialVariantInProductManagementVue,
    ProcessingUserEmployees,
    ReturnBackState,
    OnHoldItem,
    SendToStock,
    OnFittingItem,
    ReturnBackFitting,
    ReturnBackOnHold,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      orderStateStatus: null,
      orderNumberState: null,
      orderItemIdState: null,
      clientNameStatus: null,
      completedSteps: null,
      qualitycheck: null,
      currentStateOfArticle: null,
      stateEmployee: null,
      orderItemIdEmployee: null,
      editEmployee: false,
      itemId: '',
      employeeId: null,
      articleNumber: '',
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      countryCallingCode: countryCallingCodes,
      showS: false,
      dasSize: '',
      showDescription: false,
      client: client.Status,
      stateType: client.stateType,
      showHide: false,
      showM: false,
      showO: false,
      changeModal: false,
      showTeamInModal: false,
      showLocation: false,
      showTeamControlCheck: false,
      rows: [],
      activeId: '',
      orderStatus: '',
      activeOrderStatus: '',
      orders: [],
      page: 1,
      pageSize: 15,
      oldStatus: '',
      das: '',
      nState: '',
      orderItemId: '',
      comingStatus: {
        orderNumber: {
          type: String,
        },
        orderItemId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      teamNameStatus: {
        orderNumber: {
          type: String,
        },
        orderItemId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      locationStatus: {
        orderNumber: {
          type: String,
        },
        orderItemId: {
          type: String,
        },
        newState: {
          type: String,
        },
        notes: {
          type: String,
        },
      },
      controlCheckStatus: {
        orderNumber: {
          type: String,
        },
        orderItemId: {
          type: String,
        },
        newState: {
          type: String,
        },
      },
      qualityFailed: {
        orderNumber: {
          type: String,
        },
        orderItemId: {
          type: String,
        },
        description: {
          type: String,
        },
      },
      ordNum: '',
      stagesXharra: [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },

        {
          name: this.$t('ReadyToDeliver'),
          value: 'ReadyToDeliver',
        },
        {
          name: this.$t('Delivered'),
          value: 'Delivered',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 'QualityCheckNotPassed',
        },
      ],
      stagesNila: [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('InHandSewing'),
          value: 'InHandSewing',
        },
        {
          name: this.$t('InControlCheck'),
          value: 'InControlCheck',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },

        {
          name: this.$t('ReadyToDeliver'),
          value: 'ReadyToDeliver',
        },
        {
          name: this.$t('Delivered'),
          value: 'Delivered',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 'QualityCheckNotPassed',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getProcessingEmployees', 'getDescriptionOfOrder', 'getTeamOverview', 'getimagesOrder', 'getTwentyPrinciples', 'getProbeDates', 'getimagesProductionManagement',
      'getTeamsNames', 'getOrderItemSize', 'getTotalItemsForTeams', 'getTeamOrders', 'getArticles', 'getMaterialVariantsOfOrder', 'getLoggedInUser', 'getIsLoading']),
    stages() {
      if (this.client == 'NotaBrillant') {
        return [
          {
            name: this.$t('Created'),
            value: 'Created',
          },
          {
            name: this.$t('Assigned'),
            value: 'Assigned',
          },
          {
            name: this.$t('Confirmed'),
            value: 'Confirmed',
          },
          {
            name: this.$t('InPattern'),
            value: 'InPattern',
          },
          {
            name: this.$t('InPatternQualityPassed'),
            value: 'InPatternQualityPassed',
          },
          {
            name: this.$t('InPatternQualityNotPassed'),
            value: 'InPatternQualityNotPassed',
          },
          {
            name: this.$t('InPatternCompleted'),
            value: 'InPatternCompleted',
          },
          {
            name: this.$t('InSewingAssigned'),
            value: 'InSewingAssigned',
          },
          {
            name: this.$t('InSewingConfirmed'),
            value: 'InSewingConfirmed',
          },
          {
            name: this.$t('InSewing'),
            value: 'InSewing',
          },
          {
            name: this.$t('InSewingQualityPassed'),
            value: 'InSewingQualityPassed',
          },
          {
            name: this.$t('InSewingQualityNotPassed'),
            value: 'InSewingQualityNotPassed',
          },
          {
            name: this.$t('InSewingCompleted'),
            value: 'InSewingCompleted',
          },
          {
            name: this.$t('InstallationAssigned'),
            value: 'InHandSewingAssigned',
          },
          {
            name: this.$t('InstallationConfirmed'),
            value: 'InHandSewingConfirmed',
          },
          {
            name: this.$t('Installation'),
            value: 'InHandSewing',
          },
          {
            name: this.$t('InstallationQualityPassed'),
            value: 'InHandSewingQualityPassed',
          },
          {
            name: this.$t('InstallationQualityNotPassed'),
            value: 'InHandSewingQualityNotPassed',
          },
          {
            name: this.$t('InstallationCompleted'),
            value: 'InHandSewingCompleted',
          },
          {
            name: this.$t('InControlCheckAssigned'),
            value: 'InControlCheckAssigned',
          },
          {
            name: this.$t('InControlCheckConfirmed'),
            value: 'InControlCheckConfirmed',
          },
          {
            name: this.$t('InControlCheck'),
            value: 'InControlCheck',
          },
          {
            name: this.$t('QualityPassed'),
            value: 'QualityPassed',
          },
          {
            name: this.$t('InPackingAssigned'),
            value: 'InPackingAssigned',
          },
          {
            name: this.$t('InPackingConfirmed'),
            value: 'InPackingConfirmed',
          },
          {
            name: this.$t('InPacking'),
            value: 'InPacking',
          },
          {
            name: this.$t('ReadyToDeliver'),
            value: 'ReadyToDeliver',
          },
          {
            name: this.$t('Delivered'),
            value: 'Delivered',
          },
          {
            name: this.$t('OnHold'),
            value: 'OnHold',
          },
          {
            name: this.$t('StockNotAvailable'),
            value: 'StockNotAvailable',
          },
          {
            name: this.$t('QualityCheckNotPassed'),
            value: 'QualityCheckNotPassed',
          },
        ]
      }
      return [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InPatternQualityPassed'),
          value: 'InPatternQualityPassed',
        },
        {
          name: this.$t('InPatternQualityNotPassed'),
          value: 'InPatternQualityNotPassed',
        },
        {
          name: this.$t('InPatternCompleted'),
          value: 'InPatternCompleted',
        },
        {
          name: this.$t('InSewingAssigned'),
          value: 'InSewingAssigned',
        },
        {
          name: this.$t('InSewingConfirmed'),
          value: 'InSewingConfirmed',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('InSewingQualityPassed'),
          value: 'InSewingQualityPassed',
        },
        {
          name: this.$t('InSewingQualityNotPassed'),
          value: 'InSewingQualityNotPassed',
        },
        {
          name: this.$t('InSewingCompleted'),
          value: 'InSewingCompleted',
        },
        {
          name: this.$t('InHandSewingAssigned'),
          value: 'InHandSewingAssigned',
        },
        {
          name: this.$t('InHandSewingConfirmed'),
          value: 'InHandSewingConfirmed',
        },
        {
          name: this.$t('InHandSewing'),
          value: 'InHandSewing',
        },
        {
          name: this.$t('InHandSewingQualityPassed'),
          value: 'InHandSewingQualityPassed',
        },
        {
          name: this.$t('InHandSewingQualityNotPassed'),
          value: 'InHandSewingQualityNotPassed',
        },
        {
          name: this.$t('InHandSewingCompleted'),
          value: 'InHandSewingCompleted',
        },
        {
          name: this.$t('InControlCheckAssigned'),
          value: 'InControlCheckAssigned',
        },
        {
          name: this.$t('InControlCheckConfirmed'),
          value: 'InControlCheckConfirmed',
        },
        {
          name: this.$t('InControlCheck'),
          value: 'InControlCheck',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },
        {
          name: this.$t('InPackingAssigned'),
          value: 'InPackingAssigned',
        },
        {
          name: this.$t('InPackingConfirmed'),
          value: 'InPackingConfirmed',
        },
        {
          name: this.$t('InPacking'),
          value: 'InPacking',
        },
        {
          name: this.$t('ReadyToDeliver'),
          value: 'ReadyToDeliver',
        },
        {
          name: this.$t('Delivered'),
          value: 'Delivered',
        },
        {
          name: this.$t('Fitting'),
          value: 'Fitting',
        },
        {
          name: this.$t('OnHold'),
          value: 'OnHold',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 'QualityCheckNotPassed',
        },
      ]
    },
  },
  watch: {
    startDate(value) {
      if (value && this.endDate != null) {
        if (new Date(value) > new Date(this.endDate)) {
          this.endDate = value;
        }
        this.teamNames({
          startFilterPickUpDate: value,
          endFilterPickUpDate: this.endDate,
        });
      } else if (!value) {
        this.endDate = null;
        this.teamNames({
          startFilterPickUpDate: null,
          endFilterPickUpDate: null,
        });
      }
      this.showO = false
      this.showHide = false
    },
    endDate(value) {
      if (value && this.startDate != null) {
        if (new Date(value) < new Date(this.startDate)) {
          this.startDate = value;
        }
        this.teamNames({
          startFilterPickUpDate: this.startDate,
          endFilterPickUpDate: value,
        });
      } else if (!value) {
        this.startDate = null;
        this.teamNames({
          startFilterPickUpDate: null,
          endFilterPickUpDate: null,
        });
      }
      this.showO = false
      this.showHide = false
    },
    editEmployee(value) {
      if (value == true) {
        this.teamByTeamNameId(this.activeId)
      }
    },
    filters: {
      handler() {
        this.$router.replace({ name: 'Production management', query: { page: this.page, pageSize: this.pageSize } }).catch(() => {})
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions(['teamNames',
      'assignEmployeeToOrderItem',
      'teamByTeamNameId',
      'orderItemStates',
      'getFileByOrderNumber',
      'loadTwentyPrinciplesOrSize',
      'getOrderItemsManagementBy',
      'getOrderById',
      'updateOrderStatus',
      'materialVariantsByOrderNAndArticleN',
      'resetMaterialVariantsOfOrders',
      'updateToQualityNotPassed',
      'changeLoadingtoTrue',
      'resergetOrderById',
      'loadProbeDates',
      'loadPrices',
      'getFileByArticleNumber',
      'loadDescriptionOfOrder',
      'resetTwentyPrinciplesOrSize',
      'resetDescriptionOfOrder',
      'teamNames',
      'locations_by_type',
      'resetGetOrderItemsManagementBy',
      'dropdown_TeamNames',
      'loadOrderItemLocation',
      'resetLoadOrderItemLocation',
      'trackProcessingEmployees',
      'addProcessingEmployees',
      'assignProcessinEmployee',
      'updateOrderStatusForce',
      'sentToHold',
      'sentToFitting',
      'updateOrderStatusToFitting',
      'updateOrderStatusToHold',
      'sentStock',
    ]),
    returnToState(value) {
      this.updateOrderStatusForce({
        object: value,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: value.orderNumber,
            id: this.activeId,
          });
          this.getOrderById(
            {
              id: this.activeId,
              salesType: 'Product',
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            },
          )
          this.showO = false
        },
      })
    },
    returnToFitting(value) {
      this.updateOrderStatusToFitting({
        object: value,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: value.orderNumber,
            id: this.activeId,
          });
          this.getOrderById(
            {
              id: this.activeId,
              salesType: 'Product',
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            },
          )
          this.showO = false
        },
      })
    },
    returnToHold(value) {
      this.updateOrderStatusToHold({
        object: value,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: value.orderNumber,
            id: this.activeId,
          });
          this.getOrderById(
            {
              id: this.activeId,
              salesType: 'Product',
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            },
          )
          this.showO = false
        },
      })
    },
    returnHold(value) {
      this.sentToHold({
        object: value,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: this.orderNumberState,
            id: this.activeId,
          });
          this.getOrderById(
            {
              id: this.activeId,
              salesType: 'Product',
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            },
          )
          this.showO = false
        },
      })
    },
    returnToStock(value) {
      this.sentStock({
        object: value,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: this.orderNumberState,
            id: this.activeId,
          });
          this.getOrderById(
            {
              id: this.activeId,
              salesType: 'Product',
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            },
          )
          this.showO = false
        },
      })
    },
    returnFitting(value) {
      this.sentToFitting({
        object: value,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: this.orderNumberState,
            id: this.activeId,
          });
          this.getOrderById(
            {
              id: this.activeId,
              salesType: 'Product',
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            },
          )
          this.showO = false
        },
      })
    },
    showTeams(orderNumber, orderItemId) {
      this.orderNumberState = orderNumber
      this.orderItemIdState = orderItemId
      this.dropdown_TeamNames(20)
      this.orderItemStates()
    },
    showLocations(orderNumber, orderItemId, clientId) {
      this.orderNumberState = orderNumber
      this.orderItemIdState = orderItemId
      this.clientNameStatus = clientId
      this.locations_by_type('WarehouseForClient')
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)


        await this.teamNames({
          startFilterPickUpDate: this.startDate,
          endFilterPickUpDate: this.endDate,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true);
          });

        if (this.getLoggedInUser.role === 'TeamLeader') {
          await this.getTeam(this.getTeamsNames[0].teamNameId);
        }
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    addEmployees(obj) {
      console.log('oj', obj)
      this.assignProcessinEmployee({
        object: obj,
        successCallback: () => {
          this.trackProcessingEmployees({
            orderItemId: this.orderItemIdEmployee,
            currentState: this.stateEmployee,
          })
        },
      })
    },
    callEmployees(itemd, itemz, itemzz) {
      this.orderItemIdEmployee = itemz
      this.stateEmployee = itemd
      this.currentStateOfArticle = itemzz
      this.trackProcessingEmployees({
        orderItemId: itemz,
        currentState: itemd,
      })
      this.teamByTeamNameId(this.activeId)
    },
    shortWords(name) {
      if (name.length > 25) {
        return `${name.slice(0, 25)}...`;
      }
      return name;
    },
    loadAllDetails(orderItem) {
      this.orderItemId = orderItem;
      this.loadProbeDates(orderItem);
      this.loadPrices(orderItem)
    },
    async loadOrderImages(value) {
      this.$store.commit('SET_IMAGES_ORDER', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      await this.getFileByOrderNumber(value);
      this.image.imgs = []
      for (let i = 0; i < this.getimagesOrder.length; i++) {
        const image = this.getimagesOrder[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
    },
    async getAllImages(value) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      await this.getFileByArticleNumber(value);
      this.image.imgs = []
      for (let i = 0; i < this.getimagesProductionManagement.length; i++) {
        const image = this.getimagesProductionManagement[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          fileDataId: image.fileDataId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
    },
    loadEmployees(value) {
      this.teamByTeamNameId(this.activeId)
      this.itemId = value
    },
    editProcessingUser(value) {
      const object = {
        orderItemId: value, // Added a colon after 'orderItemId'
        message: '',
        processingUserId: this.employeeId,
      };

      this.assignEmployeeToOrderItem({
        object,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: this.activeOrderStatus,
            id: this.activeId,
          });
        },
      });
    },
    assignProcessingEmployee(object) {
      this.assignEmployeeToOrderItem({
        object,
        successCallback: () => {
          this.getOrderItemsManagementBy({
            orderNr: this.activeOrderStatus,
            id: this.activeId,
          });
        },
      });
    },
    async orderItem(orderN, activeId, item) {
      this.orderStateStatus = item


      if (this.activeOrderStatus === orderN && this.showO) { // Condition to skip request
        this.activeOrderStatus = ''
        this.showO = false
      } else {
        this.activeOrderStatus = orderN
        this.showO = true
        this.resetGetOrderItemsManagementBy()
        await this.getOrderItemsManagementBy({
          orderNr: orderN,
          id: activeId,
        })
      }

      this.resetMaterialVariantsOfOrders()
      this.showM = false
      // this.changeLoadingtoTrue(true)
    },
    async loadS(orderItemUnik) {
      // this.changeLoadingtoTrue(false)

      this.resetTwentyPrinciplesOrSize()
      await this.loadTwentyPrinciplesOrSize(orderItemUnik);
      this.showS = !this.showS;

      if (this.dasSize === orderItemUnik) {
        this.dasSize = '';
        this.showS = false;
      } else {
        this.dasSize = orderItemUnik;
        this.showS = true;
      }
      // this.changeLoadingtoTrue(true)
    },
    async loadDescription(value) {
      // this.changeLoadingtoTrue(false)

      this.resetDescriptionOfOrder()
      await this.loadDescriptionOfOrder(value);
      // this.changeLoadingtoTrue(true)
    },
    changeS(item, oNumber, oStatus, orderItem, articleNumber, itemz, name) {
      if (
        (oStatus === 'Created' && item === 'Assigned')
    || (oStatus === 'Assigned' && item === 'Confirmed')
    || (oStatus === 'Confirmed' && item === 'InPattern')
    || (oStatus === 'InPattern' && (item === 'InPatternQualityPassed' || item === 'InPatternQualityNotPassed'))
    || (oStatus === 'InPatternQualityPassed' && item === 'InPatternCompleted')
    || (oStatus === 'InPatternCompleted' && item === 'InSewingAssigned')
    || (oStatus === 'InSewingAssigned' && item === 'InSewingConfirmed')
    || (oStatus === 'InSewingConfirmed' && item === 'InSewing')
    || (oStatus === 'InSewing' && (item === 'InSewingQualityPassed' || item === 'InSewingQualityNotPassed'))
    || (oStatus === 'InSewingQualityPassed' && item === 'InSewingCompleted')
    || (oStatus === 'InSewingCompleted' && item === 'InHandSewingAssigned')
    || (oStatus === 'InHandSewingAssigned' && item === 'InHandSewingConfirmed')
    || (oStatus === 'InHandSewingConfirmed' && item === 'InHandSewing')
    || (oStatus === 'InHandSewing' && (item === 'InHandSewingQualityPassed' || item === 'InHandSewingQualityNotPassed'))
    || (oStatus === 'InHandSewingQualityPassed' && item === 'InHandSewingCompleted')
    || (oStatus === 'InHandSewingCompleted' && item === 'InControlCheckAssigned')
    || (oStatus === 'InControlCheckAssigned' && item === 'InControlCheckConfirmed')
    || (oStatus === 'InControlCheckConfirmed' && item === 'InControlCheck')
    || (oStatus === 'InControlCheck' && (item === 'QualityPassed' || item === 'QualityCheckNotPassed'))
    || (oStatus === 'QualityPassed' && item === 'InPackingAssigned')
    || (oStatus === 'InPackingAssigned' && item === 'InPackingConfirmed')
    || (oStatus === 'InPackingConfirmed' && item === 'InPacking')
    || (oStatus === 'InPacking' && item === 'ReadyToDeliver')
    || (oStatus === 'ReadyToDeliver' && item === 'Delivered')
      ) {
        if (this.client == 'FjollaNila') {
          this.$bvModal.show('modal-change-status-nila');
        } else if (this.client == 'NotaBrillant') {
          this.$bvModal.show('modal-change-status-nota');
        } else {
          this.$bvModal.show('modal-change-status');
          console.log('testmodal')
          this.qualitycheck = itemz
        }
        this.articleNumber = articleNumber
        if (item === 'QualityCheckNotPassed') {
          this.qualityFailed.orderNumber = oNumber;
          this.qualityFailed.orderItemId = orderItem;
          this.oldStatus = oStatus;
          this.completedSteps = false
          this.changeModal = true;
          this.showLocation = false;
          this.showTeamInModal = true;
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.orderItemStates()
          this.nState = item;
        } else if (item === 'InHandSewing' && this.client !== 'DrenushaXharra') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InPatternCompleted') {
          this.teamNameStatus.orderNumber = oNumber;
          this.teamNameStatus.orderItemId = orderItem;
          this.teamNameStatus.newState = item;
          this.teamNameStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.locations_by_type('WarehouseForClient');
          this.showTeamControlCheck = false
          this.nState = item;
        } else if (item === 'InSewing' && this.client === 'FjollaNila') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InPattern' && this.client === 'ValdrinSahiti') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InPackingAssigned') {
          this.teamNameStatus.orderNumber = oNumber;
          this.teamNameStatus.orderItemId = orderItem;
          this.teamNameStatus.newState = item;
          this.teamNameStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InSewingAssigned') {
          this.teamNameStatus.orderNumber = oNumber;
          this.teamNameStatus.orderItemId = orderItem;
          this.teamNameStatus.newState = item;
          this.teamNameStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InHandSewingAssigned') {
          this.teamNameStatus.orderNumber = oNumber;
          this.teamNameStatus.orderItemId = orderItem;
          this.teamNameStatus.newState = item;
          this.teamNameStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InControlCheckAssigned') {
          this.teamNameStatus.orderNumber = oNumber;
          this.teamNameStatus.orderItemId = orderItem;
          this.teamNameStatus.newState = item;
          this.teamNameStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InSewing') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InPatternQualityNotPassed') {
          this.locationStatus.orderNumber = oNumber;
          this.locationStatus.orderItemId = orderItem;
          this.locationStatus.newState = item;
          this.locationStatus.newStateNota = name;
          this.completedSteps = false
          // this.locationStatus.new = item;
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.nState = item;
        } else if (item === 'InSewingQualityNotPassed') {
          this.locationStatus.orderNumber = oNumber;
          this.locationStatus.orderItemId = orderItem;
          this.locationStatus.newState = item;
          this.locationStatus.newStateNota = name;
          // this.locationStatus.new = item;
          this.completedSteps = false
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.nState = item;
        } else if (item === 'InHandSewingQualityNotPassed') {
          this.locationStatus.orderNumber = oNumber;
          this.locationStatus.orderItemId = orderItem;
          this.locationStatus.newState = item;
          this.locationStatus.newStateNota = name;
          // this.locationStatus.new = item;
          this.completedSteps = false
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.nState = item;
        } else if (item === 'ReadyToDeliver') {
          this.locationStatus.orderNumber = oNumber;
          this.locationStatus.orderItemId = orderItem;
          this.locationStatus.newState = item;
          this.locationStatus.newStateNota = name;
          // this.locationStatus.new = item;
          this.completedSteps = false
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.locations_by_type('WarehouseForClient');
          this.nState = item;
        } else if (item === 'InControlCheck' && this.client === 'FjollaNila') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InControlCheck') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InSewingConfirmed') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = true
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.orderItemStates()
          this.nState = item;
        } else if (item === 'InHandSewingConfirmed') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = true
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.orderItemStates()
          this.nState = item;
        } else if (item === 'InControlCheckConfirmed') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.comingStatus.newStateNota = name;
          this.completedSteps = true
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.orderItemStates()
          this.nState = item;
        } else {
          this.comingStatus.newState = item;
          this.comingStatus.orderNumber = oNumber;
          this.oldStatus = oStatus;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newStateNota = name;
          this.completedSteps = false
          this.changeModal = false;
          this.showTeamInModal = false;
          this.showLocation = false;
          this.showTeamControlCheck = false
          this.teamByTeamNameId(this.activeId)
          this.locations_by_type('WarehouseForClient');
        }
      }
    },
    changeSNila(item, oNumber, oStatus, orderItem, articleNumber) {
      if (
        (oStatus === 'Created' && item === 'Assigned')
    || (oStatus === 'Assigned' && item === 'Confirmed')
    || (oStatus === 'Confirmed' && item === 'InPattern')
    || (oStatus === 'InPattern' && item === 'InSewing')
    || (oStatus === 'InSewing' && item === 'InHandSewing')
    || (oStatus === 'InHandSewing' && item === 'InControlCheck')
    || (oStatus === 'InControlCheck' && (item === 'QualityPassed' || item === 'QualityCheckNotPassed'))
    || (oStatus === 'QualityPassed' && item === 'ReadyToDeliver')
    || (oStatus === 'ReadyToDeliver' && item === 'Delivered')
      ) {
        this.$bvModal.show('modal-change-status');
        this.articleNumber = articleNumber
        if (item === 'QualityCheckNotPassed') {
          this.qualityFailed.orderNumber = oNumber;
          this.qualityFailed.orderItemId = orderItem;
          this.oldStatus = oStatus;
          this.changeModal = true;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.nState = item;
        } else if (item === 'InSewing') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'InHandSewing') {
          this.teamNameStatus.orderNumber = oNumber;
          this.teamNameStatus.orderItemId = orderItem;
          this.teamNameStatus.newState = item;
          this.showTeamInModal = true;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.teamByTeamNameId(this.activeId)
          this.dropdown_TeamNames(20);
          this.nState = item;
        } else if (item === 'InControlCheck') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.showTeamControlCheck = false;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'ReadyToDeliver') {
          this.locationStatus.orderNumber = oNumber;
          this.locationStatus.orderItemId = orderItem;
          this.locationStatus.newState = item;
          // this.locationStatus.new = item;
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.locations_by_type('WarehouseForClient');
          this.nState = item;
        } else {
          this.teamByTeamNameId(this.activeId)
          this.comingStatus.newState = item;
          this.comingStatus.orderNumber = oNumber;
          this.oldStatus = oStatus;
          this.comingStatus.orderItemId = orderItem;
          this.changeModal = false;
          this.showTeamInModal = false;
          this.showLocation = false;
          this.showTeamControlCheck = false
        }
      }
    },
    changeSXharra(item, oNumber, oStatus, orderItem, articleNumber) {
      if (
        (oStatus === 'Created' && item === 'Assigned')
      || (oStatus === 'Assigned' && item === 'Confirmed')
      || (oStatus === 'Confirmed' && item === 'InPattern')
      || (oStatus === 'InPattern' && item === 'InSewing')
      || (oStatus === 'InSewing' && (item === 'QualityPassed' || item === 'QualityCheckNotPassed'))
      || (oStatus === 'QualityPassed' && item === 'ReadyToDeliver')
      || (oStatus === 'ReadyToDeliver' && item === 'Delivered')
      ) {
        this.$bvModal.show('modal-change-status');
        this.articleNumber = articleNumber
        if (item === 'QualityCheckNotPassed') {
          this.qualityFailed.orderNumber = oNumber;
          this.qualityFailed.orderItemId = orderItem;
          this.oldStatus = oStatus;
          this.changeModal = true;
          this.showLocation = false;
          this.showTeamInModal = false;
          this.nState = item;
        } else if (item === 'InSewing') {
          this.comingStatus.orderNumber = oNumber;
          this.comingStatus.orderItemId = orderItem;
          this.comingStatus.newState = item;
          this.showTeamInModal = false;
          this.showLocation = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          this.dropdown_TeamNames(20);
          this.teamByTeamNameId(this.activeId)
          this.nState = item;
        } else if (item === 'ReadyToDeliver') {
          this.locationStatus.orderNumber = oNumber;
          this.locationStatus.orderItemId = orderItem;
          this.locationStatus.newState = item;
          // this.locationStatus.new = item;
          this.showLocation = true;
          this.showTeamInModal = false;
          this.changeModal = false;
          this.showTeamControlCheck = false
          // this.locations_by_type('WarehouseForClient');
          this.nState = item;
        } else {
          this.comingStatus.newState = item;
          this.teamByTeamNameId(this.activeId)
          this.comingStatus.orderNumber = oNumber;
          this.oldStatus = oStatus;
          this.comingStatus.orderItemId = orderItem;
          this.changeModal = false;
          this.showTeamInModal = false;
          this.showLocation = false;
          this.showTeamControlCheck = false
        }
      }
    },
    async changeStage(item) {
      if (item.newState == 'ReadyToDeliver') {
        this.orderStateStatus = 'ReadyToDeliver'
      }
      if (Object.prototype.hasOwnProperty.call(item, 'description')) {
        await this.updateToQualityNotPassed({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: this.qualityFailed.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )
            this.qualityFailed.newState = null
            this.qualityFailed.teamNameId = null
          },
        });
      } else if (item.forceChangeOrderItems == true) {
        console.log('item', item)
        await this.updateOrderStatusForce({
          object: item.cStatus,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )

            this.teamNames({
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            })
          },
        });
      } else if (item.newState === 'InSewing') {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )

            this.teamNames({
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            })
          },
        });
      } else if (item.newState === 'InHandSewing') {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )

            this.teamNames({
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            })
          },
        });
      } else if (item.newState === 'ReadyToDeliver') {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )
          },
        });
      } else if (item.newState === 'Delivered') {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )
          },
        });
      } else if (item.newState === 'InControlCheck') {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )
            this.teamNames({
              startFilterPickUpDate: this.startDate,
              endFilterPickUpDate: this.endDate,
            })
          },
        });
      } else {
        await this.updateOrderStatus({
          object: item,
          successCallback: () => {
            this.getOrderItemsManagementBy({
              orderNr: item.orderNumber,
              id: this.activeId,
            });
            this.getOrderById(
              {
                id: this.activeId,
                salesType: 'Product',
                startFilterPickUpDate: this.startDate,
                endFilterPickUpDate: this.endDate,
              },
            )
          },
        });
      }
    },
    async showMaterials(orderN, orderItemUnik) {
      // this.changeLoadingtoTrue(false)

      await this.materialVariantsByOrderNAndArticleN({
        orderNumber: orderN,
        orderItemId: orderItemUnik,
      });
      this.showM = !this.showM;
      if (this.das === orderItemUnik) {
        this.das = '';
        this.showM = false;
      } else {
        this.das = orderItemUnik;
        this.showM = true;
      }
      // this.changeLoadingtoTrue(true)
    },
    async loadOrders(value) {
      this.resetLoadOrderItemLocation()
      await this.loadOrderItemLocation({
        orderItemId: value,
        articleNumber: null,
        clientId: null,
      })
    },
    async getTeam(id) {
      // this.changeLoadingtoTrue(false)

      this.resergetOrderById()
      if (this.activeId === id && this.showHide) { // Condition to skip request
        this.activeId = ''
        this.showHide = false
      } else {
        this.activeId = id
        this.showHide = true
        await this.getOrderById(
          {
            id,
            salesType: 'Product',
            startFilterPickUpDate: this.startDate,
            endFilterPickUpDate: this.endDate,
          },
        )
      }

      // this.changeLoadingtoTrue(true)
    },
  },
};
</script>

<style scoped lang="scss">

.main {
    padding: 15px 15px 15px 120px;
    display: flex;
    justify-content: space-between;
}
.main1 {
    padding: 65px 15px 15px 120px;
    display: flex;
}

.notHovered{
  background: #FF274F !important;
    color: #f4f1ed;
    border-color: transparent;
    width:10%
}
.hovered{
  background: white !important;
    color: #FF274F;
    border:1px solid #FF274F ;
    width:10%

}
.iconat{
  width:80px;
  height: 80px;
}
.second-div{
  display: none;
}
.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}
.activeOrders{
  display: flex;
  justify-content: space-between;
  width: 88%;
}

.activeOrders b{
  color:#15E582;
}
.hr{
  background-color: grey;
  min-width: 201px;
  height: 1px;
  border: 0.5px solid #A5A5A5;
  opacity: 0.34;
}
.material-info:last-child {
  margin-right: 0 !important;
}
.pm-li {
  cursor:pointer;
  list-style: none;
  background: #ffffff;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #DADBDC;
  // box-shadow: none;
  padding: 12px 10px;
  margin-bottom: 20px;
}
.pm-li h5 {
  margin-bottom: 0;
}
.ul-order {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  cursor:default;
  background: #F6F6F6;
  border-radius: 8px;
  // margin-top: 1%;
}
.ul-order li {
  background: #F6F6F6;
  overflow-y: auto;
  // border-radius: 8px;
  border-bottom: 1px solid #DADBDC;
  padding: 0px 10px 6px 10px;
  margin-top: 15px;
  color:black ; font-weight: 400;font-size: 15px;
}
.ul-order ul {
  padding-left: 5px;
}

.order-card {
  cursor:default;
  display: flex;
}

.order-card p {
  margin-right: 20px;
  color:black ; font-weight: 500;font-size: 15px;
  word-break: break-word;
  border-right: 1px solid #393939;
}
.stage-card {
  background: #ffffff;
  border-radius: 4px;
  // box-shadow: none;
  padding: 10px;
  margin-bottom: 15px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.3;
}
.stage-card:hover {
 background: #ffe8fc;
}
.stage-card.active {
  background: none !important;
  color: #f4f1ed;
  border-color: transparent;
  opacity: 1 !important;
}
.stage-card.activee {
  background-color: grey;
  opacity: 1;
  border-image-slice: 1;
  border-width: 5px;
}
.stage-card.disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}
.black-bg {
  background:white;
  opacity: 0.9;
  color: black;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.black-bg:hover {
  background:white;
  opacity: 0.9;
  color: black;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.faileedd:hover{
  background: #FF274F !important;
  opacity: 0.7;
  color: #ffffff;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.faileedd {
  background: #FF274F !important;
  opacity: 0.7;
  color: #ffffff;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.stage-card.failed {
  background: #FF274F !important;
  opacity: 0.7;
  color: #ffffff;
  border-top:2px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to left, #e51515  70% , #424242 );
}
.order-header{
  display: flex;
  font: normal normal bold 15px/19px;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
}
.order-header{
  color:#393939 ; font-weight: 400; font-size: 14px;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .main {
    padding: 35px 15px 15px 90px !important;
  }
  .main1 {
    padding: 65px 15px 15px 90px;
    display: flex;
}
}

 @media screen and (max-width: 1200px){
  .iconat{
  width:35px;
  height: 35px;
}
  .first-div{
    display: none;

  }
  .second-div{
    display: block;
  }
  .second-div p {
    font-size: 12px;
    color:black;
  }
  .activeOrders b{
    color:#15E582;
    margin-right: 20px;
  }
  .stage-card{
    width: 205px;
  }
 }
 @media screen and (max-width: 1190px) {

.main {
  padding: 35px 0.75rem 0 0.75rem !important;
  /* justify-content: center; */
}
.main1 {
    padding: 65px 0.75rem 0px 0.75rem;
    display: flex;
}
}
</style>
